/**
 * Theme for NPR US site
 */
import { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types.d';
import { buildThemeVarsUsLW, buildThemeMixinsUsLW, buildThemeWidgetStylesUsLW } from 'dw-us-law/theme';
import { gothamFont } from '../common/nextFont';

export const buildThemeVarsUsNPR = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const nprRed = '#DA252B'; //color1
  const nprDarkRed = '#991A1E'; //color2
  const nprGrey = '#EDEDED'; //color6
  const nprMaxRed = '#D62021';
  const nprRadioBlue = '#1E7FC3';
  const grey60 = '#616161';
  const nprRed1 = '#E92440';

  const gotham = gothamFont.style.fontFamily;

  const lwThemeVars = buildThemeVarsUsLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'NPR US',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo-mobile.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo-with-partner.svg`,
    },
    colors: {
      primary1: nprRed,
      primary1BtnHover: nprDarkRed,
      btnDisabled: nprGrey,
      secondaryArticleHeader: nprMaxRed,
      btnsPrimaryBg: nprRed,
      btnsPrimaryHoverBg: nprDarkRed,
      radioBtnsPrimaryBg: nprRadioBlue,
      textPrimaryBg: nprRed,
      grey60: grey60,
      textRed: nprRed1,
      btnsSecondaryHoverBg: nprDarkRed,
      stockPill: nprRed,
    },
    fonts: {
      familyPrimary: gotham,
      familySecondary: lwThemeVars.fonts.roboto,
      familyTertiary: gotham,
      sizeH1: '35px',
      headerMainNavTitle: gotham,
      headerMainNavSubTitle: gotham,
      weightMedium: '325',
    },
  });
};
export const buildThemeMixinsUsNPR = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const buildThemeWidget = buildThemeMixinsUsLW(vars);

  return merge({}, buildThemeWidget, {
    hxl: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize44};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize64};
      }
    `,
    h1: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize40};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize48};
      }
    `,
    h2: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize36};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize40};
      }
    `,
    h3: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize30};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize36};
      }
    `,
    h4: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize24};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize30};
      }
    `,
    h5: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize22};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
    `,
    h6: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize20};
      line-height: ${vars.fonts.fontLineHeight115};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize24};
      }
    `,
    h7: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize18};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize22};
      }
    `,
    h8: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-size: ${vars.fonts.fontSize16};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
    `,
    eyebrowRegular: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightSemiBold};
      font-size: ${vars.fonts.fontSize12};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize14};
      }
    `,
    eyebrowSmall: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightSemiBold};
      font-size: ${vars.fonts.fontSize10};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
      }
    `,
    utility: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightSemiBold};
      font-size: ${vars.fonts.fontSize10};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing9};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
        line-height: ${vars.fonts.fontLineHeight12};
        letter-spacing: ${vars.fonts.fontLetterSpacing8};
      }
    `,
    quote: `
      font-family: ${vars.fonts.familyPrimary};
      font-weight: ${vars.fonts.weightLight};
      font-style: ${vars.fonts.styleItalic};
      font-size: ${vars.fonts.fontSize20};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize28};
      }
    `,
    body1: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightNormal};
      font-size: ${vars.fonts.fontSize16};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
    body2: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightNormal};
      font-size: ${vars.fonts.fontSize14};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
    bodySmall: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightNormal};
      font-size: ${vars.fonts.fontSize12};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
    caption: `
      font-family: ${vars.fonts.familySecondary};
      font-weight: ${vars.fonts.weightNormal};
      font-size: ${vars.fonts.fontSize12};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
    `,
  });
};
export const buildThemeWidgetStylesUsNPR = (vars: Omit<Theme, 'widgets'>) => {
  const themeStyles = buildThemeWidgetStylesUsLW(vars);

  return merge({}, themeStyles, {
    Footer: {
      default: `
      ${themeStyles.Footer.default}
      .footer-bottom-section {
        .wrapper {
          .brand img {
              max-width: 20em;
              ${vars.breakpoints.lg} {
                max-width: 23.5em;
              }
            }
          }

          .copyrights {
            &> p:last-child {
              margin-top: 1.125em;

              &> a {
                text-decoration: underline;

                &:first-child {
                  margin-right: 1em;
                }

                &:not(:first-child) {
                  margin-left: 1em;
                }
              }
            }
          }
        }
      }
    `,
      navItems: `
      ${themeStyles.Footer.navItems}
    `,
    },
    Search: {
      wrapper: `
      ${themeStyles.Search.wrapper}
      .addToCart-container {
        .cart-slider {
          button {
            font-weight: ${vars.fonts.weightMediumPlus};
          }
        }
      }
      `,
      hitWrapper: ` 
      ${themeStyles.Search.hitWrapper}
      &.list-view { 
        .ais-Hits-list {
          .ais-Hits-item {
            .image-bg {
              .image-container {
                .offer {
                  .stock {
                    background: ${vars.colors.stockPill};
                  }
                }
              }
            }
          }
        } 
      }
      &.grid-view { 
        .ais-Hits-list {
          .ais-Hits-item {
            .image-bg {
              .image-container {
                .offer {
                  .stock {
                    background: ${vars.colors.stockPill};
                  }
                }
              }
            }
          }
        }
      }
      `,
    },
    DrawerMenu: {
      mobileNavMenuContainer: `
      ${themeStyles.DrawerMenu.mobileNavMenuContainer}
      .mantine-Drawer-body & .level1Link span:hover {
        color: ${vars.colors.primary1};
      }
      .mantine-Drawer-body & a.level1Link:hover {
        color: ${vars.colors.primary1};
      }
      `,
    },
    CmsArticle: {
      wrapper: `
      ${themeStyles.CmsArticle.wrapper}
      .secondary {
        color: ${vars.colors.secondaryArticleHeader} !important;
      }`,
    },
    Cart: {
      MiniCart: `
      ${themeStyles.Cart.MiniCart}
      .mini-cart-header {
        .mini-cart-header-text {
          text-transform: lowercase;
          font-weight: ${vars.fonts.weightBold};
        }
        p.mini-cart-header-text:first-letter {
          text-transform: uppercase;
        }
      }
      .product-great-pick{
        font-weight: ${vars.fonts.weightMedium};
        line-height: 27.6px;
        font-size: 24px;
      }
        .product-link {
          font-weight: ${vars.fonts.weightMedium};
          line-height: 27.6px;
          font-size: 24px;
        }
        .price-section {
          font-weight: ${vars.fonts.weightMedium};
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .price-section .price {
          line-height: 27.6px;
          font-size: 24px;
          font-weight: ${vars.fonts.weightMedium};
        }
        .price-section .strike {
          color: ${vars.colors.grey60};
          line-height: 26.4px;
          font-size: 22px;
          font-weight: ${vars.fonts.weightMedium};
        }
        .price-section .strike+.sale-price{
          color: ${vars.colors.textRed};
        }
        .price-section .strike+.member-price {
          color:${vars.colors.blue600};
        }
        .member-savings {
          letter-spacing: 0.02em;
        }
      `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsUsNPR, buildThemeMixinsUsNPR, buildThemeWidgetStylesUsNPR);

export default createTheme;
